.DocsPage-section{
 margin-bottom: 1.25rem;
}

.DocsPage-section-title {
    margin-bottom: 0.35rem;
}

.DocsPage-section-item {
    margin-bottom: 0.2rem;
}